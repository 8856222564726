'use strict';

angular.module('enervexSalesappApp').factory('Account', function ($resource) {
	return $resource('/api/accounts/:id/:merge/:member/:userId', {
		id: '@_id',
		merge: '@merge'
	},
	{
		update: {
			method: 'PUT'
		},
		merge: {
			method: "POST",
			params: {
				merge: 'merge'
			}
		},
		removeMember: {
			method: "DELETE",
			params: {
				member: 'member',
				userId: '@userid'
			}
		},
		userMember: {
			isArray: true,
			method: "GET",
			params: {
				member: 'member',
				userId: '@userId'
			}
		},
		addToAllOwner: {
			isArray: false,
			method: "POST",
			params: {
				member: 'account-owner',
				userId: '@userId'
			}
		},
		removeMemberFromAll: {
			isArray: false,
			method: "DELETE",
			params: {
				member: 'account-owner',
				userId: '@userId'
			}
		},
	});
});
